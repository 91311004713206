@import '../../../styles/variables';
    body{
      background: var(--bottom-bar-color) !important;
    }


#kai-container {
    position: absolute;
    background: linear-gradient(0deg, var(--main-background-color), #ffffff);
    left: 0;
    right: 0;
    bottom: var(--bottom-bar-height);
    padding-bottom: 50px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overflow-x:hidden;
    z-index: 2;
 }

 .kai_container{
  top: 0px;
  transition: height, top 1s ease;
  &.avatarTitleDisplayed {
    transition: var(--transition-avatar-title);
    top: var(--avatar-title-height);
  }
  &.languageSelection {
    top: var(--header-bar-top-height);
  }
  &.languageSelection.avatarTitleDisplayed {
    transition: var(--transition-avatar-title);
    top: calc(var(--header-bar-top-height) + var(--avatar-title-height));
  }
  &.avatarHeaderBar {
  top: calc(var(--header-bar-height) - var(--header-bar-bottom-height));
  }
  &.avatarHeaderBar.avatarTitleDisplayed {
    transition: var(--transition-avatar-title);
    top: calc(var(--header-bar-height) - var(--header-bar-bottom-height) + var(--avatar-title-height));
  }
  &.avatarHeaderBar.languageSelection {
    top: calc(var(--header-bar-height) - var(--header-bar-bottom-height) + var(--header-bar-top-height));
  }
  &.avatarHeaderBar.languageSelection.avatarTitleDisplayed {
    transition: var(--transition-avatar-title);
    top: calc(var(--header-bar-height) - var(--header-bar-bottom-height) + var(--header-bar-top-height) + var(--avatar-title-height));
  }
  &.smallHeaderBar{
    top: calc(var(--header-bar-height-small) - var(--header-bar-bottom-height));
  }
  &.smallHeaderBar.avatarTitleDisplayed {
    transition: var(--transition-avatar-title);
    top: calc(var(--header-bar-height-small) - var(--header-bar-bottom-height) + var(--avatar-title-height));
  }
  &.smallHeaderBar.languageSelection {
    top: calc(var(--header-bar-height-small) - var(--header-bar-bottom-height) + var(--header-bar-top-height));
  }
  &.smallHeaderBar.languageSelection.avatarTitleDisplayed {
    transition: var(--transition-avatar-title);
    top: calc(var(--header-bar-height-small) - var(--header-bar-bottom-height) + var(--header-bar-top-height) + var(--avatar-title-height));
  }
 }

 .kai-inner-container {
   padding: 0px 0px 10px 0px;
   &.languageSelectHeader {
    margin-top: var(--header-bar-height-small)
   }
  }


 #kai-component-bottom {
  position: fixed;
  bottom: var(--bottom-bar-height);
 }  
 
.screen-reader-carousel-buttons {
  display: none;
}

.webview-container:not(.avatar_transition) .kai_container {
  top: 0px;
  &.languageSelection {
    top: var(--header-bar-top-height);
  }
  &.avatarHeaderBar.avatarTitleDisplayed {
    top: calc(var(--avatar-title-height));
  }
  &.languageSelection.avatarTitleDisplayed {
    top: calc(var(--header-bar-top-height) + var(--avatar-title-height));
  }
}



/* Fixes for responsive webview */
#kai-container.shortcut_widget {
  height: calc(100% - var(--header-bar-height) - var(--bottom-bar-height));
}