@import '../../../styles/variables';
#webview-container {
  ul {
    padding: 0 0 0 20px;
    margin: 0 0 0 0px;
  }

  li {
    margin-bottom: 10px;
  }

  .kai-chat-message-text, .livechat-notification-message {
    font-size: var(--text-bubble-font-size);

    &.fixed {
      overflow: hidden;
    }

    &.fixed.kai-left:before {
      content: '';
      width: 100%;
      height: 80px;
      position: absolute;
      left: 0;
      bottom: 0;
      border-radius: 15px;
      background: linear-gradient(var(--background-gradient-starting-color-rgb) 0px, var(--background-gradient-ending-color-rgb));
    }

    p,
    li,
    ul,
    ol {
      font-size: inherit;
      font-weight: inherit;
      margin: inherit;
      color: inherit;
    }
  }

  .livechat-notification-message {
    word-break: break-word;
  }

  .kai-left-wrapper .kai-chat-wrapper, .kai-right-wrapper .kai-chat-wrapper, .kai-typing-indicator  .kai-chat-wrapper {
    display: flex;
  }

  .kai-right-wrapper .kai-chat-wrapper {
    flex-direction: row-reverse;
  }

  .kai-chat-row {
    &.kai-answer-shown {
      box-shadow: 1px 2px 11px -2px rgba(0,0,0,0.33);
      border-radius: 10px;
    }
  }

  .kai-chat-message-title {
    font-size: var(--text-bubble-font-size);
    font-weight: bold;
  }

  .kai-chat-message.kai-left {
    background: var(--left-message-color);
    //border: 1px solid #f1f0f0;
    color: var(--left-message-text-color);
    margin-left: 0px;
    width: 100%;
    float: left;
    border-radius: 10px 10px 10px 0px;
    margin-bottom: 0px;
    word-break: break-word;
    box-shadow: var(--shadow);
    // border-left: 9px solid var(--primary-action-color);
    overflow: hidden;
    padding: 10px 10px 10px 19px;
    &.last-left-msg-bubble{
      border-radius: 10px 10px 10px 0px;
    }
    &.kai-answer-shown {
      border-radius: 10px 10px 0px 0px;
      box-shadow: none;
      min-height: 100px;
    }
    &.kai-stream-event {
      padding: 10px 10px 10px 10px;
      background-color: var(--main-background-color);
      border-radius: 10px;
    }
  }

  .kai-chat-row {
    max-width: var(--text-bubble-and-button-max-width)
  }


  .kai-chat-message.kai-right {
    float: right;
    border: none;
    background: var(--right-message-color);
    color: var(--right-message-text-color);
    border-radius: 10px 10px 0px 10px;
    margin-bottom: 7px;
    box-shadow: var(--shadow);
    overflow: hidden;
    padding: 10px 19px 10px 10px;
    margin-right: 5px;

    }

  .kai-chat-message {
    border-radius: 5px;
    font-size: var(--font-sub-title-size);
    margin-bottom: 20px;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
    transform: translateY(0);
    position: relative;
    z-index: 10;

    &:focus {
      outline: none;
      box-shadow: 0 0 5px 3px var(--highlight-color);
    }
  }

  .kai-message-icon {
    position: relative;
    margin-right: 10px;
  }

  .kai-message-icon img,
  .kai-message-icon .svg-wrapper {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    float: left;
    box-shadow: var(--shadow);
  }

  .kai-message-icon .svg-wrapper {
    background-color: var(--primary-action-color);
  }

  .kai-message-icon .svg-wrapper svg {
    color: var(--live-chat-logo-color);
    width: 100%;
    height: auto;
  }

  .no-profile-icon-space {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 5px;
    float: left;
  }

  .left-border {
    background-color: var(--primary-action-color);
    width: 10px;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    border-radius: 10px 0 0 0;
  }

  .right-border {
    background-color: var(--secondary-action-color);
    width: 10px;
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 0 10px 0 0;
  }

  .context-menu-wrap{
    align-self: center;
  }


  .kai-answer-source{
    padding: 5px 10px 5px 20px;
    background-color: var(--main-background-color);
    border-radius: 0 0 10px 10px;
    display: flex;
    clear: both;
    align-items: center;
    justify-content: space-between;
    span {
      font-weight: bolder;
    }
    a {
      font-weight: bold;
    }
    &.list-inline-button-item {
      line-height: 24px;
      height: 27px;
    }
  }

  .kai-flex-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .kai-gap-50 {
    gap: 50px;
  }

  .kai-gap-10 {
    gap: 10px;
  }

  .kai-chat-caption-copy {
    margin-right: 5px;
  }

  .kai-chat-caption-copy svg {
    cursor: pointer;
    cursor: hand;
    color: var(--playground-3-color);
  }
  .kai-chat-caption-copy svg:hover {
    color: var(--secondary--action-color);
    // display on the same line as kai-chat-message-subtext
  }

  .kai-chat-caption-title {
    display: inline;
    font-size: var(--text-bubble-font-size);
    font-style: italic;
    margin-left: 5px;
  }

  .kai-chat-caption {
    // display kai-chat-message-subtext under kai-chat-message bubble
    display: inline-flex;
    margin-top: 5px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .kai_chat_no_title {
    // display copy button at the end when there's no title
    justify-content: flex-end !important;
  }
}