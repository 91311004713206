@import '../../../styles/variables';
#webview-container {

    #related-questions-container-float {
        padding: 0px 20px 0px 20px;
        margin: 0 5px;
        clear: both;
    }

    .related-questions-inner-float {
        color: var(--text-color);
        margin-left: 0px;
        float: left;
        border-radius: 15px;
        margin-bottom: 10px;
        word-break: break-word;
        border: 1px dashed var(--playground-3-color);
        padding: 7px 10px 8px 19px;
        &:focus{
            outline: none;
            box-shadow: 0 0 5px 3px var(--highlight-color);
        }
    }

    .related-questions-title, .keywords-title {
        font-weight: bold;
        font-size: var(--font-sub-title-size);
        text-decoration: underline;
        text-underline-offset: 4px;
    }

    .keywords-container {
        margin-bottom: 15px;
    }

    .keywords-title {
        text-decoration: underline;
        text-underline-offset: 4px;
    }

    .keyword-list-container {
        display: flex;
        gap: 10px;
        flex-flow: wrap;
        margin-bottom: 5px;
    }

    .keyword-btn {
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
        border: 1px solid #aaa;
        padding: 0 10px;
        color: var(--primary-action-color);
        text-decoration: none !important;
    }

    .keyword-remove-text {
        white-space: nowrap;
    }

    .keyword-remove-icon {
        cursor: pointer;
        margin-top: 1px;
    }

    .suggested-keywords-container {
        font-size: var(--font-sub-title-size);
    }
    
    .suggested-keyword {
        text-decoration: underline;
        text-underline-offset: 2px;
        padding: 0 2px;

        &:hover {
            cursor: pointer;
            font-weight: bold;
        }
    }
    

    .related-questions-inner-item {
        /* padding: 6px 0 25px 0; */
        /* margin: 0 auto; */
        height: 50px;
        border-top: 1px solid var(--playground-2-color);
        /* place-items: center; */
        /* display: inline-table; */
        /* top: 50%; */
        /* margin-top: 10px; */
        /* position: absolute; */
        display: table;
        width: 100%;

        &.main-border {
          border-top: none;
        }

        & .context-menu{
            & p {
                font-size: var(--related-questions-answer-font-size);
            }
        }

        
        &:hover .related-questions-text{
            text-decoration: underline;
            font-weight: bold;
        }
    }

    .related-questions-text {
        width: 100%;
        padding: 8px 0;
        /* float: left; */
        font-size: var(--related-questions-text-font-size);
        display: table-cell;
        /* height: 20px; */
        /* position: absolute; */
        vertical-align: middle;
        cursor: pointer;
        cursor: hand;
    }


    .related-questions-btn-wrapper {
        display: table-cell;
        vertical-align: middle;
        & button:focus{
            outline: none;
            box-shadow: 0 0 5px 3px var(--highlight-color);
        }
    }

    .related-questions-title-btn-wrapper {
        float: right;
        bottom: 5px;
        position: relative;
        display: table-cell;
    }

    .related-questions-title-btn {
        float: right;
        text-align: center;
        border-radius: 5px;
        font-weight: bold;
        cursor: hand;
        border: none;
        outline: none;
        font-size: var(--card-button-font-size);
        background-color: transparent;
        border:none;
        width: 19px;
        height: 19px;
        padding: 0px;

        // &:focus{
        //     // outline: none;
        //     // box-shadow: 0 0 5px 3px var(--highlight-color);
        // }

        &.up-arrow {
            background-color: transparent;
            img {
                width: 12px;
                margin-top: -7px;
            }
        }

        &.down-arrow {
            border:none;
            img {
                transform: rotate(180deg);
                width: 12px;
                margin-top: -4px
            }
        }

        & .kai-tooltip-text {
            right: -5px !important;
            bottom: calc(-100% - 7px);

            &::after {
                right: 10px;
                left: initial !important;
                bottom:100%;
                top:initial;
                border-color: transparent transparent var(--right-message-color) transparent;
            }
        }
    }
}