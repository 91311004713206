/* Set color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set text-color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set color to all accent components (e.g., card headers, etc.)*/
/****************************************************/
/* Global Styles  */
/****************************************************/
html.webview--app {
  -webkit-text-size-adjust: 100%;
  height: 100vh;
  justify-content: flex-start;
}
.webview--app {
  /****************************************************/
  /* Message Row  */
  /****************************************************/
  /****************************************************/
  /* Google map autocomplete  */
  /****************************************************/
  /****************************************************/
  /* Thumb Icon  */
  /****************************************************/
  /****************************************************/
  /* Input Styling  */
  /****************************************************/
}
.webview--app .kai-modal-backdrop {
  display: none;
}
.webview--app .kai-modal {
  background: rgba(0, 0, 0, 0.5);
}
.webview--app #locationPickerModal {
  z-index: 999;
}
.webview--app .kai-row,
.webview--app .clear {
  padding: 10px 20px 0px 20px;
  margin: 5px;
  clear: both;
}
.webview--app .kai-clear {
  padding: 0px 20px 0px 20px;
  margin: 5px;
  clear: both;
}
.webview--app .kai-left {
  float: left;
}
.webview--app .kai-right {
  float: right;
}
.webview--app .kai-left-wrapper {
  position: relative;
}
.webview--app .kai-left-wrapper.show-icon {
  padding-left: 5px;
}
.webview--app .kai-left-wrapper.hidden-icon {
  padding-left: 50px;
}
.webview--app .kai-typing-indicator {
  position: relative;
}
.webview--app .kai-typing-indicator.show-icon {
  padding-left: 5px;
}
.webview--app .kai-typing-indicator.hidden-icon {
  padding-left: 50px;
}
.webview--app .msg-row {
  /* clear: both;
      margin-bottom: -10px !important;
    */
}
.webview--app .pac-container {
  z-index: 100001 !important;
}
.webview--app img.thumb-icon {
  /* margin-top: 8px; */
  vertical-align: middle;
  display: inline !important;
  user-select: none;
}
.webview--app .kai-right .thumb-icon {
  margin-top: 0px;
}
.webview--app input[type='text'],
.webview--app textarea {
  resize: none !important;
}
.webview-container *,
.powered_by_container *,
.webview--app .dateCalendar-module *,
.live-chat-widget *,
.live-chat-widget-panel *,
.webview-container :after,
.powered_by_container :after,
.webview--app .dateCalendar-module :after,
.live-chat-widget :after,
.live-chat-widget-panel :after,
.webview-container :before,
.powered_by_container :before,
.webview--app .dateCalendar-module :before,
.live-chat-widget :before,
.live-chat-widget-panel :before {
  box-sizing: border-box;
  direction: ltr;
}
.webview-container.webview_rtl_support div,
.powered_by_container.webview_rtl_support div,
.webview--app .dateCalendar-module.webview_rtl_support div,
.live-chat-widget.webview_rtl_support div,
.live-chat-widget-panel.webview_rtl_support div,
.webview-container.webview_rtl_support textarea,
.powered_by_container.webview_rtl_support textarea,
.webview--app .dateCalendar-module.webview_rtl_support textarea,
.live-chat-widget.webview_rtl_support textarea,
.live-chat-widget-panel.webview_rtl_support textarea,
.webview-container.webview_rtl_support input,
.powered_by_container.webview_rtl_support input,
.webview--app .dateCalendar-module.webview_rtl_support input,
.live-chat-widget.webview_rtl_support input,
.live-chat-widget-panel.webview_rtl_support input,
.webview-container.webview_rtl_support label,
.powered_by_container.webview_rtl_support label,
.webview--app .dateCalendar-module.webview_rtl_support label,
.live-chat-widget.webview_rtl_support label,
.live-chat-widget-panel.webview_rtl_support label {
  direction: rtl;
}
.webview-container,
.powered_by_container {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-size-adjust: 100%;
}
.webview-container button,
.powered_by_container button,
.webview-container input,
.powered_by_container input,
.webview-container optgroup,
.powered_by_container optgroup,
.webview-container select,
.powered_by_container select,
.webview-container textarea,
.powered_by_container textarea {
  margin: 0;
  line-height: inherit;
}
.webview-container button,
.powered_by_container button,
.webview-container input,
.powered_by_container input,
.webview-container optgroup,
.powered_by_container optgroup,
.webview-container select,
.powered_by_container select {
  font-family: inherit;
  font-size: inherit;
}
.webview-container img,
.powered_by_container img {
  border-style: none;
}
.webview-container img,
.powered_by_container img,
.webview-container svg,
.powered_by_container svg {
  vertical-align: middle;
}
.webview-container a,
.powered_by_container a {
  color: var(--primary-action-color) !important;
  text-decoration: underline !important;
  word-break: break-word;
}
.webview-container a:hover,
.powered_by_container a:hover {
  color: var(--hover-color) !important;
}
.webview-container a:focus,
.powered_by_container a:focus {
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}
.webview-container pre,
.powered_by_container pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}
.webview-container #locationPickerModal button:focus,
.powered_by_container #locationPickerModal button:focus,
.webview-container #locationPickerModal .form-control:focus,
.powered_by_container #locationPickerModal .form-control:focus {
  outline: none;
  border-color: #00000000;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}
.webview-container .kai-modal-open .kai-modal,
.powered_by_container .kai-modal-open .kai-modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.webview-container .kai-modal,
.powered_by_container .kai-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.webview-container .fade,
.powered_by_container .fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.webview-container .kai-modal.fade .kai-modal-dialog,
.powered_by_container .kai-modal.fade .kai-modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
}
.webview-container .kai-modal.show .kai-modal-dialog,
.powered_by_container .kai-modal.show .kai-modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.webview-container .kai-modal-dialog,
.powered_by_container .kai-modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
@media (min-width: 576px) {
  .webview-container .kai-modal-dialog,
  .powered_by_container .kai-modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}
.webview-container .kai-modal-content,
.powered_by_container .kai-modal-content {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
@media (min-width: 768px) {
  .webview-container .container,
  .powered_by_container .container,
  .webview-container .container-md,
  .powered_by_container .container-md,
  .webview-container .container-sm,
  .powered_by_container .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .webview-container .container,
  .powered_by_container .container,
  .webview-container .container-sm,
  .powered_by_container .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .webview-container .container,
  .powered_by_container .container {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .webview-container .container,
  .powered_by_container .container {
    max-width: 540px;
  }
}
.webview-container .container,
.powered_by_container .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.webview-container .kai-modal-header,
.powered_by_container .kai-modal-header {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.webview-container .kai-modal-body,
.powered_by_container .kai-modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
}
.webview-container .kai-modal-footer,
.powered_by_container .kai-modal-footer {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.webview-container h1,
.powered_by_container h1,
.webview-container h2,
.powered_by_container h2,
.webview-container h3,
.powered_by_container h3,
.webview-container h4,
.powered_by_container h4,
.webview-container h5,
.powered_by_container h5,
.webview-container h6,
.powered_by_container h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.webview-container .h1,
.powered_by_container .h1,
.webview-container .h2,
.powered_by_container .h2,
.webview-container .h3,
.powered_by_container .h3,
.webview-container .h4,
.powered_by_container .h4,
.webview-container .h5,
.powered_by_container .h5,
.webview-container .h6,
.powered_by_container .h6,
.webview-container h1,
.powered_by_container h1,
.webview-container h2,
.powered_by_container h2,
.webview-container h3,
.powered_by_container h3,
.webview-container h4,
.powered_by_container h4,
.webview-container h5,
.powered_by_container h5,
.webview-container h6,
.powered_by_container h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.webview-container .h4,
.powered_by_container .h4,
.webview-container h4,
.powered_by_container h4 {
  font-size: 1.5rem;
}
.webview-container .kai-modal-title,
.powered_by_container .kai-modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.webview-container .close,
.powered_by_container .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.webview-container button.close,
.powered_by_container button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.webview-container [type=button]:not(:disabled),
.powered_by_container [type=button]:not(:disabled),
.webview-container [type=reset]:not(:disabled),
.powered_by_container [type=reset]:not(:disabled),
.webview-container [type=submit]:not(:disabled),
.powered_by_container [type=submit]:not(:disabled),
.webview-container button:not(:disabled),
.powered_by_container button:not(:disabled) {
  cursor: pointer;
}
.webview-container .kai-modal-header .close,
.powered_by_container .kai-modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem -1rem;
}
.webview-container .form-group,
.powered_by_container .form-group {
  margin-bottom: 1rem;
}
.webview-container label,
.powered_by_container label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.webview-container .col,
.powered_by_container .col,
.webview-container .col-1,
.powered_by_container .col-1,
.webview-container .col-2,
.powered_by_container .col-2,
.webview-container .col-3,
.powered_by_container .col-3,
.webview-container .col-4,
.powered_by_container .col-4,
.webview-container .col-5,
.powered_by_container .col-5,
.webview-container .col-6,
.powered_by_container .col-6,
.webview-container .col-7,
.powered_by_container .col-7,
.webview-container .col-8,
.powered_by_container .col-8,
.webview-container .col-9,
.powered_by_container .col-9,
.webview-container .col-10,
.powered_by_container .col-10,
.webview-container .col-11,
.powered_by_container .col-11,
.webview-container .col-12,
.powered_by_container .col-12,
.webview-container .col-auto,
.powered_by_container .col-auto,
.webview-container .col-lg,
.powered_by_container .col-lg,
.webview-container .col-lg-1,
.powered_by_container .col-lg-1,
.webview-container .col-lg-2,
.powered_by_container .col-lg-2,
.webview-container .col-lg-3,
.powered_by_container .col-lg-3,
.webview-container .col-lg-4,
.powered_by_container .col-lg-4,
.webview-container .col-lg-5,
.powered_by_container .col-lg-5,
.webview-container .col-lg-6,
.powered_by_container .col-lg-6,
.webview-container .col-lg-7,
.powered_by_container .col-lg-7,
.webview-container .col-lg-8,
.powered_by_container .col-lg-8,
.webview-container .col-lg-9,
.powered_by_container .col-lg-9,
.webview-container .col-lg-10,
.powered_by_container .col-lg-10,
.webview-container .col-lg-11,
.powered_by_container .col-lg-11,
.webview-container .col-lg-12,
.powered_by_container .col-lg-12,
.webview-container .col-lg-auto,
.powered_by_container .col-lg-auto,
.webview-container .col-md,
.powered_by_container .col-md,
.webview-container .col-md-1,
.powered_by_container .col-md-1,
.webview-container .col-md-2,
.powered_by_container .col-md-2,
.webview-container .col-md-3,
.powered_by_container .col-md-3,
.webview-container .col-md-4,
.powered_by_container .col-md-4,
.webview-container .col-md-5,
.powered_by_container .col-md-5,
.webview-container .col-md-6,
.powered_by_container .col-md-6,
.webview-container .col-md-7,
.powered_by_container .col-md-7,
.webview-container .col-md-8,
.powered_by_container .col-md-8,
.webview-container .col-md-9,
.powered_by_container .col-md-9,
.webview-container .col-md-10,
.powered_by_container .col-md-10,
.webview-container .col-md-11,
.powered_by_container .col-md-11,
.webview-container .col-md-12,
.powered_by_container .col-md-12,
.webview-container .col-md-auto,
.powered_by_container .col-md-auto,
.webview-container .col-sm,
.powered_by_container .col-sm,
.webview-container .col-sm-1,
.powered_by_container .col-sm-1,
.webview-container .col-sm-2,
.powered_by_container .col-sm-2,
.webview-container .col-sm-3,
.powered_by_container .col-sm-3,
.webview-container .col-sm-4,
.powered_by_container .col-sm-4,
.webview-container .col-sm-5,
.powered_by_container .col-sm-5,
.webview-container .col-sm-6,
.powered_by_container .col-sm-6,
.webview-container .col-sm-7,
.powered_by_container .col-sm-7,
.webview-container .col-sm-8,
.powered_by_container .col-sm-8,
.webview-container .col-sm-9,
.powered_by_container .col-sm-9,
.webview-container .col-sm-10,
.powered_by_container .col-sm-10,
.webview-container .col-sm-11,
.powered_by_container .col-sm-11,
.webview-container .col-sm-12,
.powered_by_container .col-sm-12,
.webview-container .col-sm-auto,
.powered_by_container .col-sm-auto,
.webview-container .col-xl,
.powered_by_container .col-xl,
.webview-container .col-xl-1,
.powered_by_container .col-xl-1,
.webview-container .col-xl-2,
.powered_by_container .col-xl-2,
.webview-container .col-xl-3,
.powered_by_container .col-xl-3,
.webview-container .col-xl-4,
.powered_by_container .col-xl-4,
.webview-container .col-xl-5,
.powered_by_container .col-xl-5,
.webview-container .col-xl-6,
.powered_by_container .col-xl-6,
.webview-container .col-xl-7,
.powered_by_container .col-xl-7,
.webview-container .col-xl-8,
.powered_by_container .col-xl-8,
.webview-container .col-xl-9,
.powered_by_container .col-xl-9,
.webview-container .col-xl-10,
.powered_by_container .col-xl-10,
.webview-container .col-xl-11,
.powered_by_container .col-xl-11,
.webview-container .col-xl-12,
.powered_by_container .col-xl-12,
.webview-container .col-xl-auto,
.powered_by_container .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 576px) {
  .webview-container .col-sm-2,
  .powered_by_container .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}
.webview-container button,
.powered_by_container button,
.webview-container input,
.powered_by_container input {
  overflow: visible;
}
.webview-container .form-control,
.powered_by_container .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
.webview-container [type=button],
.powered_by_container [type=button],
.webview-container [type=reset],
.powered_by_container [type=reset],
.webview-container [type=submit],
.powered_by_container [type=submit],
.webview-container button,
.powered_by_container button {
  -webkit-appearance: button;
}
.webview-container button,
.powered_by_container button,
.webview-container select,
.powered_by_container select {
  text-transform: none;
}
.webview-container .btn,
.powered_by_container .btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
.webview-container .btn-outline-secondary,
.powered_by_container .btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.webview-container .kai-modal-footer > *,
.powered_by_container .kai-modal-footer > * {
  margin: 0.25rem;
}
.webview-container [type=button]:not(:disabled),
.powered_by_container [type=button]:not(:disabled),
.webview-container [type=reset]:not(:disabled),
.powered_by_container [type=reset]:not(:disabled),
.webview-container [type=submit]:not(:disabled),
.powered_by_container [type=submit]:not(:disabled),
.webview-container button:not(:disabled),
.powered_by_container button:not(:disabled) {
  cursor: pointer;
}
#webview-container {
  border-top: 0;
}
.webview-container .kai-tooltip,
.live-chat-widget .kai-tooltip,
.live-chat-widget-panel .kai-tooltip,
.powered_by_container .kai-tooltip,
.webview--app .dateCalendar-module .kai-tooltip {
  position: relative;
  display: inline-block;
}
.webview-container .kai-tooltip .kai-tooltip-text,
.live-chat-widget .kai-tooltip .kai-tooltip-text,
.live-chat-widget-panel .kai-tooltip .kai-tooltip-text,
.powered_by_container .kai-tooltip .kai-tooltip-text,
.webview--app .dateCalendar-module .kai-tooltip .kai-tooltip-text {
  visibility: hidden;
  min-width: 11px;
  background-color: var(--right-message-color);
  color: var(--secondary-action-text-color);
  text-align: center;
  border-radius: 6px;
  white-space: nowrap;
  padding: 5px 7px;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  z-index: 100;
  bottom: calc(100% + 5px);
  margin-left: -5px;
  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 1s;
}
.webview-container .kai-tooltip .kai-tooltip-text::after,
.live-chat-widget .kai-tooltip .kai-tooltip-text::after,
.live-chat-widget-panel .kai-tooltip .kai-tooltip-text::after,
.powered_by_container .kai-tooltip .kai-tooltip-text::after,
.webview--app .dateCalendar-module .kai-tooltip .kai-tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 11px;
  border-width: 4px;
  border-style: solid;
  border-color: var(--right-message-color) transparent transparent transparent;
}
.webview-container .kai-tooltip:hover .kai-tooltip-text,
.live-chat-widget .kai-tooltip:hover .kai-tooltip-text,
.live-chat-widget-panel .kai-tooltip:hover .kai-tooltip-text,
.powered_by_container .kai-tooltip:hover .kai-tooltip-text,
.webview--app .dateCalendar-module .kai-tooltip:hover .kai-tooltip-text {
  visibility: visible;
  opacity: 1;
}
.webview--app {
  --main-background-color: #f2f2f2;
  --component-background-color: #ffffff;
  --background-gradient-starting-color-rgb: rgba(255, 255, 255, 0);
  --background-gradient-ending-color-rgb: #ffffff;
  --left-message-color: #ffffff;
  --left-message-text-color: #000000;
  --right-message-color: #323a45;
  --right-message-text-color: #ffffff;
  --text-bubble-and-button-max-width: 222px;
  --text-color: #1c2029;
  --font-title-size: 20px;
  --font-sub-title-size: 14px;
  --related-questions-text-font-size: 14px;
  --related-questions-answer-font-size: 14px;
  --font-size-factor: 1;
  --inline-button-font-size: calc(var(--font-size-factor) * 14px);
  --card-button-font-size: calc(var(--font-size-factor) * 14px);
  --card-header-font-size: calc(var(--font-size-factor) * 16px);
  --text-bubble-font-size: calc(var(--font-size-factor) * 12px);
  --nbest-text-font-size: calc(var(--font-size-factor) * 13px);
  --bottom-bar-font-size: calc(var(--font-size-factor) * 16px);
  --timestamp-font-size: calc(var(--font-size-factor) * 10px);
  --typing-indicator-size: 0.5em;
  --top-k-source-font-size: calc(var(--font-size-factor) * 12px);
  --primary-action-color: #00958e;
  --primary-action-text-color: #ffffff;
  --highlight-color: #B4E6D2;
  --primary-action-color-dark: #ba5413;
  --primary-action-color-darker: #964410;
  --secondary-action-color: #323a45;
  --secondary-action-text-color: #000;
  --secondary-action-color-light: #444A58;
  --live-chat-logo-color: #ffffff;
  --live-chat-end-button-color: #CF482B;
  --live-chat-end-button-color-hover: #b14228;
  --hover-color: #e56b1f;
  --hover-text-color: #ffffff;
  --hover-suggestion-color: #dddddd;
  --select-state-color: #964410;
  --select-state-text-color: #ffffff;
  --shadow: 1px 2px 11px -2px rgba(0, 0, 0, 0.33);
  --bottom-bar-color: #ffffff;
  --bottom-bar-height: 70px;
  --kai-answers-bottom-bar-height: 70px;
  --kai-answers-bottom-bar-color: transparent;
  --header-bar-height: 112px;
  --header-bar-height-small: 50px;
  --live-chat-widget-height: 52px;
  --live-chat-expand-bar-height: 30px;
  --live-chat-btn-hover-color: #959699;
  --transition-live-chat-widget: all 1s ease-in-out;
  --transition-bottom-bar: all 1s ease-in-out;
  --avatar-title-height: 24px;
  --transition-avatar-title: all 1s ease-in-out;
  --header-bar-top-height: 40px;
  --header-bar-bottom-height: 10px;
  --card-item-max-height: 600px;
  --card-large-item-max-height: 250px;
  --bubble-text-max-height: 300px;
  --card-item-width: 300px;
  --card-item-border-size: 1px;
  --chart-color-1: var(--primary-action-color);
  --chart-color-2: #6E70DB;
  --chart-color-3: #F6A320;
  --chart-color-4: #02A1AF;
  --chart-color-5: #0E6D48;
  --chart-color-6: #4E4F88;
  --chart-color-7: #B25D01;
  --chart-color-8: #037372;
  --chart-color-9: #CF482A;
  --chart-color-10: #DCB81C;
  --chart-color-11: #006FAD;
  --chart-color-12: #1B998B;
  --chart-color-13: #2E294E;
  --chart-color-14: #F46036;
  --chart-color-15: #E2C044;
  --chart-tooltip-color: #444A58;
  --chart-tooltip-text-color: #ffffff;
  --chart-percent-text-color: #ffffff;
  --playground-1-color: #F2F2F2;
  --playground-2-color: #DDDDDD;
  --playground-3-color: #959699;
  --alert-color: #FF9800;
  --shortcut-menu-button-background-color-pressed: var(--primary-action-color);
  --shortcut-menu-button-icon-color: var(--secondary-action-color);
  --shortcut-menu-button-icon-color-pressed: var(--primary-action-text-color);
  --generated-icon-color: var(--primary-action-color);
}
