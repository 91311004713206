@import './variables';

/****************************************************/

/* Global Styles  */

/****************************************************/


body.webview--app {
//  font-family: verdana;
}

html.webview--app {
    -webkit-text-size-adjust: 100%;
    height: 100vh;
    justify-content: flex-start;
}

.webview--app {

    .kai-modal-backdrop {
      display: none;
    }

    .kai-modal {
      background: rgba(0,0,0,0.5);
    }

    #locationPickerModal{
      z-index: 999; // need to be < 1000 for autocomplete address to display
    }


    /****************************************************/

    /* Message Row  */

    /****************************************************/

    .kai-row, .clear {
      padding: 10px 20px 0px 20px;
      margin: 5px;
      clear: both;
    }

    .kai-clear{
      padding: 0px 20px 0px 20px;
      margin: 5px;
      clear: both;
    }

    .kai-left {
      float: left;
    }

    .kai-right {
      float: right;
    }

    .kai-left-wrapper {
      position: relative;

      &.show-icon {
        padding-left: 5px;
      }

      &.hidden-icon {
        padding-left: 50px;
      }
    }

    .kai-typing-indicator {
      position: relative;

      &.show-icon {
        padding-left: 5px;
      }

      &.hidden-icon {
        padding-left: 50px;
      }
    }

    .msg-row {
     /* clear: both;
      margin-bottom: -10px !important;
    */
    }

    /****************************************************/

    /* Google map autocomplete  */

    /****************************************************/


    .pac-container {
       z-index: 100001 !important; 
    }

    /****************************************************/

    /* Thumb Icon  */

    /****************************************************/

    img.thumb-icon{
     /* margin-top: 8px; */
     vertical-align: middle;
     display: inline !important;
     user-select: none;
    }

    .kai-right .thumb-icon {
      margin-top: 0px;
    }

    /****************************************************/

    /* Input Styling  */

    /****************************************************/


    input[type='text'],
    textarea {
      // font-size: 15px;
      resize: none !important;
    }



}

.webview-container, .powered_by_container, .webview--app .dateCalendar-module, .live-chat-widget, .live-chat-widget-panel {
  *, :after, :before{
    box-sizing: border-box;
    direction: ltr;
  }

  &.webview_rtl_support div, &.webview_rtl_support textarea, &.webview_rtl_support input,  &.webview_rtl_support label{
    direction: rtl;
    // text-align: right;
  }
}

.webview-container, .powered_by_container{
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-size-adjust: 100%;

  button, input, optgroup, select, textarea {
    margin: 0;
    line-height: inherit;
  }

  button, input, optgroup, select {
    font-family: inherit;
    font-size: inherit;
  }

  img {
    border-style: none;
  }

  img, svg {
    vertical-align: middle;
  }

  a {
    color: var(--primary-action-color) !important;
    &:hover{
      color: var(--hover-color) !important;
    }
    &:focus{
      outline: none;
      box-shadow: 0 0 5px 3px var(--highlight-color);
    }
    text-decoration: underline !important;
    word-break: break-word;
  }

  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
  }

  #locationPickerModal button:focus, #locationPickerModal .form-control:focus {
    outline: none;
    border-color: #00000000;
    box-shadow: 0 0 5px 3px var(--highlight-color);
  }

  .kai-modal-open .kai-modal {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .kai-modal {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1050;
      display: none;
      width: 100%;
      height: 100%;
      overflow: hidden;
      outline: 0;
  }
  .fade {
      -webkit-transition: opacity .15s linear;
      transition: opacity .15s linear;
  }

  .kai-modal.fade .kai-modal-dialog {
      transition: -webkit-transform .3s ease-out;
      -webkit-transition: -webkit-transform .3s ease-out;
      transition: transform .3s ease-out;
      transition: transform .3s ease-out,-webkit-transform .3s ease-out;
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
  }


  .kai-modal.show .kai-modal-dialog {
    -webkit-transform: none;
    transform: none;
  }


  .kai-modal-dialog {
      position: relative;
      width: auto;
      margin: 0.5rem;
      pointer-events: none;
  }

  @media (min-width: 576px) {
    .kai-modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
  }

  .kai-modal-content {
      position: relative;
      display: -ms-flexbox;
      display: -webkit-box;
      display: flex;
      -ms-flex-direction: column;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0,0,0,.2);
      border-radius: 0.3rem;
      outline: 0;
  }

  @media (min-width: 768px) {
    .container, .container-md, .container-sm {
        max-width: 720px;
    }
  }

  @media (min-width: 576px) {
    .container, .container-sm {
        max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
  }
  @media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
  }
  .container {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
  }

  .kai-modal-header {
      display: -ms-flexbox;
      display: -webkit-box;
      display: flex;
      -ms-flex-align: start;
      -webkit-box-align: start;
      align-items: flex-start;
      -ms-flex-pack: justify;
      -webkit-box-pack: justify;
      justify-content: space-between;
      padding: 1rem 1rem;
      border-bottom: 1px solid #dee2e6;
      border-top-left-radius: calc(0.3rem - 1px);
      border-top-right-radius: calc(0.3rem - 1px);
  }

  .kai-modal-body {
      position: relative;
      -ms-flex: 1 1 auto;
      -webkit-box-flex: 1;
      flex: 1 1 auto;
      padding: 1rem;
  }

  .kai-modal-footer {
      display: -ms-flexbox;
      display: -webkit-box;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-align: center;
      -webkit-box-align: center;
      align-items: center;
      -ms-flex-pack: end;
      -webkit-box-pack: end;
      justify-content: flex-end;
      padding: 0.75rem;
      border-top: 1px solid #dee2e6;
      border-bottom-right-radius: calc(0.3rem - 1px);
      border-bottom-left-radius: calc(0.3rem - 1px);
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }
  .h4, h4 {
    font-size: 1.5rem;
  }
  .kai-modal-title {
      margin-bottom: 0;
      line-height: 1.5;
  }

  .close {
      float: right;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: .5;
  }
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
      cursor: pointer;
  }
  .kai-modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem -1rem;
  }
  .form-group {
    margin-bottom: 1rem;
  }

  label {
      display: inline-block;
      margin-bottom: 0.5rem;
  }

  .col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (min-width: 576px) {
    .col-sm-2 {
        -ms-flex: 0 0 16.666667%;
        -webkit-box-flex: 0;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
  }

  
  button, input {
    overflow: visible;
  }

  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  }




  [type=button], [type=reset], [type=submit], button {
      -webkit-appearance: button;
  }
  button, select {
    text-transform: none;
  }
  .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  }
  .btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d;
  }
  .kai-modal-footer>* {
    margin: 0.25rem;
  }
  [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
  }

}

#webview-container{
  border-top: 0;
}

.webview-container, .live-chat-widget, .live-chat-widget-panel, .powered_by_container, .webview--app .dateCalendar-module {

  .kai-tooltip {
    position: relative;
    display: inline-block;
  }
  
  .kai-tooltip .kai-tooltip-text {
    visibility: hidden;
    min-width: 11px;
    background-color: var(--right-message-color);
    color: var(--secondary-action-text-color);
    text-align: center;
    border-radius: 6px;
    white-space: nowrap;
    padding: 5px 7px;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    z-index: 100;
    bottom: calc(100% + 5px);
    margin-left: -5px;
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
  }

  .kai-tooltip .kai-tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 11px;
    border-width: 4px;
    border-style: solid;
    border-color: var(--right-message-color) transparent transparent transparent;
  }
  
  .kai-tooltip:hover .kai-tooltip-text {
    visibility: visible;
    opacity: 1;
  }

}

.webview--app {
  --main-background-color: @main-background-color;
  --component-background-color: @component-background-color;
  --background-gradient-starting-color-rgb: @background-gradient-starting-color-rgb;
  --background-gradient-ending-color-rgb: @background-gradient-ending-color-rgb;
  --left-message-color: @left-message-color;
  --left-message-text-color: @left-message-text-color;
  --right-message-color: @right-message-color;
  --right-message-text-color: @right-message-text-color;
  --text-bubble-and-button-max-width:@text-bubble-and-button-max-width;
  --text-color: @text-color;
  --font-title-size: @font-title-size;
  --font-sub-title-size: @font-sub-title-size;
  --related-questions-text-font-size: @related-questions-text-font-size;
  --related-questions-answer-font-size: @related-questions-answer-font-size;
  --font-size-factor: @font-size-factor;
  --inline-button-font-size: calc(var(--font-size-factor) * @inline-button-font-size);
  --card-button-font-size: calc(var(--font-size-factor) * @card-button-font-size);
  --card-header-font-size: calc(var(--font-size-factor) * @card-header-font-size);
  --text-bubble-font-size: calc(var(--font-size-factor) * @text-bubble-font-size);
  --nbest-text-font-size: calc(var(--font-size-factor) * @nbest-text-font-size);
  --bottom-bar-font-size: calc(var(--font-size-factor) * @bottom-bar-font-size);
  --timestamp-font-size: calc(var(--font-size-factor) * @timestamp-font-size);
  --typing-indicator-size: @typing-indicator-size;
  --top-k-source-font-size: calc(var(--font-size-factor) * @top-k-source-font-size);


  --primary-action-color: @primary-action-color;
  --primary-action-text-color: @primary-action-text-color;
  --highlight-color:@highlight-color;
  --primary-action-color-dark:@primary-action-color-dark;
  --primary-action-color-darker:@primary-action-color-darker;

  --secondary-action-color: @secondary-action-color;
  --secondary-action-text-color: @secondary-action-text-color;
  --secondary-action-color-light: @secondary-action-color-light;

  --live-chat-logo-color: @live-chat-logo-color;
  --live-chat-end-button-color: @live-chat-end-button-color;
  --live-chat-end-button-color-hover: @live-chat-end-button-color-hover;
  --hover-color: @hover-color;
  --hover-text-color: @hover-text-color;
  --hover-suggestion-color: @hover-suggestion-color;
  --select-state-color: @select-state-color;
  --select-state-text-color: @select-state-text-color;
  --shadow: @shadow;
  --bottom-bar-color: @bottom-bar-color;
  --bottom-bar-height: @bottom-bar-height;
  --kai-answers-bottom-bar-height: @kai-answers-bottom-bar-height;
  --kai-answers-bottom-bar-color: @kai-answers-bottom-bar-color;
  --header-bar-height: @header-bar-height;
  --header-bar-height-small: @header-bar-height-small;
  --live-chat-widget-height: @live-chat-widget-height;
  --live-chat-expand-bar-height: @live-chat-expand-bar-height;
  --live-chat-btn-hover-color: @live-chat-btn-hover-color;
  --transition-live-chat-widget: @transition-live-chat-widget;
  --transition-bottom-bar: @transition-bottom-bar;
  --avatar-title-height: @avatar-title-height;
  --transition-avatar-title: @transition-avatar-title;
  --header-bar-top-height:@header-bar-top-height;
  --header-bar-bottom-height:@header-bar-bottom-height;
  --card-item-max-height: @card-item-max-height;
  --card-large-item-max-height: @card-large-item-max-height;
  --bubble-text-max-height: @bubble-text-max-height;
  --card-item-width: @card-item-width;
  --card-item-border-size: @card-item-border-size;
  --chart-color-1: var(--primary-action-color);
  --chart-color-2: @chart-color-2;
  --chart-color-3: @chart-color-3;
  --chart-color-4: @chart-color-4;
  --chart-color-5: @chart-color-5;
  --chart-color-6: @chart-color-6;
  --chart-color-7: @chart-color-7;
  --chart-color-8: @chart-color-8;
  --chart-color-9: @chart-color-9;
  --chart-color-10: @chart-color-10;
  --chart-color-11: @chart-color-11;
  --chart-color-12: @chart-color-12;
  --chart-color-13: @chart-color-13;
  --chart-color-14: @chart-color-14;
  --chart-color-15: @chart-color-15;
  --chart-tooltip-color: @chart-tooltip-color;
  --chart-tooltip-text-color: @chart-tooltip-text-color;
  --chart-percent-text-color:  @chart-percent-text-color;
  --playground-1-color: @playground-1-color;
  --playground-2-color: @playground-2-color;
  --playground-3-color: @playground-3-color;
  --alert-color: @alert-color;
  --shortcut-menu-button-background-color-pressed: var(--primary-action-color);
  --shortcut-menu-button-icon-color: var(--secondary-action-color);
  --shortcut-menu-button-icon-color-pressed: var(--primary-action-text-color);
  --generated-icon-color: var(--primary-action-color);
}