/* Set color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set text-color to all interactable components (e.g., buttons, quick replies, etc.)*/
/* Set color to all accent components (e.g., card headers, etc.)*/
#webview-container ul {
  padding: 0 0 0 20px;
  margin: 0 0 0 0px;
}
#webview-container li {
  margin-bottom: 10px;
}
#webview-container .kai-chat-message-text,
#webview-container .livechat-notification-message {
  font-size: var(--text-bubble-font-size);
}
#webview-container .kai-chat-message-text.fixed,
#webview-container .livechat-notification-message.fixed {
  overflow: hidden;
}
#webview-container .kai-chat-message-text.fixed.kai-left:before,
#webview-container .livechat-notification-message.fixed.kai-left:before {
  content: '';
  width: 100%;
  height: 80px;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 15px;
  background: linear-gradient(var(--background-gradient-starting-color-rgb) 0px, var(--background-gradient-ending-color-rgb));
}
#webview-container .kai-chat-message-text p,
#webview-container .livechat-notification-message p,
#webview-container .kai-chat-message-text li,
#webview-container .livechat-notification-message li,
#webview-container .kai-chat-message-text ul,
#webview-container .livechat-notification-message ul,
#webview-container .kai-chat-message-text ol,
#webview-container .livechat-notification-message ol {
  font-size: inherit;
  font-weight: inherit;
  margin: inherit;
  color: inherit;
}
#webview-container .livechat-notification-message {
  word-break: break-word;
}
#webview-container .kai-left-wrapper .kai-chat-wrapper,
#webview-container .kai-right-wrapper .kai-chat-wrapper,
#webview-container .kai-typing-indicator .kai-chat-wrapper {
  display: flex;
}
#webview-container .kai-right-wrapper .kai-chat-wrapper {
  flex-direction: row-reverse;
}
#webview-container .kai-chat-row.kai-answer-shown {
  box-shadow: 1px 2px 11px -2px rgba(0, 0, 0, 0.33);
  border-radius: 10px;
}
#webview-container .kai-chat-message-title {
  font-size: var(--text-bubble-font-size);
  font-weight: bold;
}
#webview-container .kai-chat-message.kai-left {
  background: var(--left-message-color);
  color: var(--left-message-text-color);
  margin-left: 0px;
  width: 100%;
  float: left;
  border-radius: 10px 10px 10px 0px;
  margin-bottom: 0px;
  word-break: break-word;
  box-shadow: var(--shadow);
  overflow: hidden;
  padding: 10px 10px 10px 19px;
}
#webview-container .kai-chat-message.kai-left.last-left-msg-bubble {
  border-radius: 10px 10px 10px 0px;
}
#webview-container .kai-chat-message.kai-left.kai-answer-shown {
  border-radius: 10px 10px 0px 0px;
  box-shadow: none;
  min-height: 100px;
}
#webview-container .kai-chat-message.kai-left.kai-stream-event {
  padding: 10px 10px 10px 10px;
  background-color: var(--main-background-color);
  border-radius: 10px;
}
#webview-container .kai-chat-row {
  max-width: var(--text-bubble-and-button-max-width);
}
#webview-container .kai-chat-message.kai-right {
  float: right;
  border: none;
  background: var(--right-message-color);
  color: var(--right-message-text-color);
  border-radius: 10px 10px 0px 10px;
  margin-bottom: 7px;
  box-shadow: var(--shadow);
  overflow: hidden;
  padding: 10px 19px 10px 10px;
  margin-right: 5px;
}
#webview-container .kai-chat-message {
  border-radius: 5px;
  font-size: var(--font-sub-title-size);
  margin-bottom: 20px;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  transform: translateY(0);
  position: relative;
  z-index: 10;
}
#webview-container .kai-chat-message:focus {
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
}
#webview-container .kai-message-icon {
  position: relative;
  margin-right: 10px;
}
#webview-container .kai-message-icon img,
#webview-container .kai-message-icon .svg-wrapper {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  float: left;
  box-shadow: var(--shadow);
}
#webview-container .kai-message-icon .svg-wrapper {
  background-color: var(--primary-action-color);
}
#webview-container .kai-message-icon .svg-wrapper svg {
  color: var(--live-chat-logo-color);
  width: 100%;
  height: auto;
}
#webview-container .no-profile-icon-space {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 5px;
  float: left;
}
#webview-container .left-border {
  background-color: var(--primary-action-color);
  width: 10px;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 10px 0 0 0;
}
#webview-container .right-border {
  background-color: var(--secondary-action-color);
  width: 10px;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  border-radius: 0 10px 0 0;
}
#webview-container .context-menu-wrap {
  align-self: center;
}
#webview-container .kai-answer-source {
  padding: 5px 10px 5px 20px;
  background-color: var(--main-background-color);
  border-radius: 0 0 10px 10px;
  display: flex;
  clear: both;
  align-items: center;
  justify-content: space-between;
}
#webview-container .kai-answer-source span {
  font-weight: bolder;
}
#webview-container .kai-answer-source a {
  font-weight: bold;
}
#webview-container .kai-answer-source.list-inline-button-item {
  line-height: 24px;
  height: 27px;
}
#webview-container .kai-flex-wrapper {
  display: flex;
  justify-content: space-between;
}
#webview-container .kai-gap-50 {
  gap: 50px;
}
#webview-container .kai-gap-10 {
  gap: 10px;
}
#webview-container .kai-chat-caption-copy {
  margin-right: 5px;
}
#webview-container .kai-chat-caption-copy svg {
  cursor: pointer;
  cursor: hand;
  color: var(--playground-3-color);
}
#webview-container .kai-chat-caption-copy svg:hover {
  color: var(--secondary--action-color);
}
#webview-container .kai-chat-caption-title {
  display: inline;
  font-size: var(--text-bubble-font-size);
  font-style: italic;
  margin-left: 5px;
}
#webview-container .kai-chat-caption {
  display: inline-flex;
  margin-top: 5px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
#webview-container .kai_chat_no_title {
  justify-content: flex-end !important;
}
